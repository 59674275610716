var object = new Avt();
$(function(){

    //LICZNIK

    const myWindow = $(window);
    var counterHome = $("#avt-statistic-box-main").find(".avt-number");
    var counters = $("#avt-statistic-box-portfolio .avt-statistic-box").find(".avt-number");
    var counterPoint = $("[data-scroll='animate-counter-point']");
    var counterLoaded = false;

    //MODAL NASZE MARKI
    var catalogMarksBtn = $(".avt-catalog-btn");
    var marksModal = $(".avt-marks-modal");

    //DO LICZNIKA
    function loadCounter(element, time){
        if($(element).length) {
            if(counterLoaded) return;
            counterLoaded =  true;
            $(element).each(function () {
                const fixed = parseInt($(this).text()) != $(this).text() || $(this).text() == 1;
                $(this).prop('Counter',0).animate({
                    Counter: $(this).text()
                }, {
                    duration: time,
                    easing: 'swing',
                    step: function (now) {
                        if (fixed) {
                            $(this).text(now.toFixed(1));
                        } else {
                            $(this).text(Math.ceil(now));
                        }
                    }
                });
            });
        }
    }


    var segmentIsotope = $("#avt-segment-isotope").find(".isotope-grid");
    if(segmentIsotope.length > 0){
        segmentIsotope.isotope({
            layoutMode: 'fitRows'
        });
        $('.filter-button-group').on( 'click', 'button', function() {
            $(this).siblings().removeClass("active");
            $(this).addClass("active");
            if(myWindow.width() < 1025){
                var filterAll = $(this).parent().find("[data-filter='']");
                $(this).parent().prepend($(filterAll));
                $(this).parent().prepend($(this));
            }
            var filterValue = $(this).attr('data-filter');
            $(".isotope-grid").isotope({ filter: filterValue });
            $(this).parent().removeClass("open");
        });
    }

    var segmentFilterBtn = $("#avt-segment-isotope").find(".avt-filter-btn");
    if(segmentFilterBtn.length > 0){
        segmentFilterBtn.on("click", function(event){
            $(this).parent().toggleClass("open");
        });
    }


    var portfolioIsotope = $("#avt-portfolio-isotope").find(".isotope-grid");
    if(portfolioIsotope.length > 0){
        portfolioIsotope.isotope();
        $('.filter-button-group').on( 'click', 'button', function() {
            $(this).siblings().removeClass("active");
            $(this).addClass("active");
            if(myWindow.width() < 1025){
                var filterAll = $(this).parent().find("[data-filter='']");
                $(this).parent().prepend($(filterAll));
                $(this).parent().prepend($(this));
            }
            var thisValue = $(this).attr('data-filter');
            var secondValue = $(this).parent().siblings().find(".active").attr("data-filter");
            var filterValue = thisValue + secondValue;
            $(".isotope-grid").isotope({ filter: filterValue });
            $(this).parent().removeClass("open");
        });
    }

    var portfolioFilterBtn = $("#avt-portfolio-isotope").find(".avt-filter-btn");
    if(portfolioFilterBtn.length > 0){
        portfolioFilterBtn.on("click", function(event){
            $(this).parent().toggleClass("open");
        });
    }


    myWindow.on("scroll", function(){
        if (counterPoint.length > 0) {
            if(myWindow.scrollTop() + myWindow.height() >= counterPoint.offset().top ){
                loadCounter(counterHome, 3000);
                loadCounter(counters, 3000);
            }
        }
    });
    catalogMarksBtn.on("click", function(){
        marksModal.css("display", "block");
        $("body").addClass("avt-stop-scroll");
        var exitBut = marksModal.find(".avt-exit-btn");
        exitBut.one("click", function(){
            marksModal.css("display", "");
            $("body").removeClass("avt-stop-scroll");
        });
    });

    // Simple scroll @Portfolio - segment
    $("#statystyki").on("click", function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $(".avt-section-statistics").offset().top
        }, 500);
    });
    $("#tytuly").on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $(".avt-marks-isotope").offset().top
        }, 750);
    });

    // Simple scroll @Reklama - Pakiety, AdFinder
    $(".reklama-pakiety-link").on("click", function(e) {
        $('html, body').animate({
            scrollTop: $("#avt-all-packages").offset().top
        }, 650);
    });
    $(".reklama-adfinder-link").on('click', function(e) {
        $('html, body').animate({
            scrollTop: $("#avt-section-adfinder").offset().top
        }, 750);
    });

    //SLIDER "NETFLIX"


    /* Downloaded from https://www.codeseek.co/ */
    var scaling = 1.50;
    //count
    var currentSliderCount = 0;
    var videoCount = $(".slider-container").children().length;
    var showCount = 4;
    var sliderCount = videoCount / showCount;
    var controlsWidth = 40;
    var scollWidth = 0;
    var sliderLoaded = false;


    $(document).ready(function(){
        if(myWindow.width() >= 1200){
            init();
        }
    });
    $( window ).resize(function() {
        if(myWindow.width() >= 1200){
            init();
        }
        else{
            clear();
        }
    });
    function clear(){
        var sliderFrame = $(".slider-frame");
        var sliderContainer = $(".slider-container");
        var slide = $(".slide");
        sliderFrame.css("height", "").css("width", "").css("top", "").css("left", "").css("margin-left", "");
        sliderContainer.css("height", "").css("width", "").css("top", "").css("left", "").css("margin-left", "");
        slide.css("height", "").css("width", "").css("top", "").css("left", "").css("margin-left", "");
    }
    function init(){
        // elements
        var win = $(window);
        var sliderFrame = $(".slider-frame");
        var sliderContainer = $(".slider-container");
        var slide = $(".slide");
        if(!(sliderLoaded)){
            sliderContainer.css("opacity", 0);
        }

        //counts
        var scollWidth = 0;


        //sizes
        var windowWidth = win.width();
        var frameWidth = win.width() - 80;
        if(windowWidth >= 0 && windowWidth <= 414){
            showCount = 2;
        }
        else if(windowWidth >= 414 &&  windowWidth <= 768){
           showCount = 3;
        }
        else{
           showCount = 6;
        }
        var videoWidth = (windowWidth  / showCount );
        var videoHeight = Math.round(videoWidth / (16/9));

        var videoWidthDiff = (videoWidth * scaling) - videoWidth;
        var videoHeightDiff = (videoHeight * scaling) - videoHeight;



        //set sizes
        sliderFrame.width(windowWidth);
        sliderFrame.height(videoHeight * scaling * 1.25);


        //sliderFrame.css("top", (videoHeightDiff / 2));

        sliderContainer.height(videoHeight * scaling);
        sliderContainer.width((videoWidth * videoCount) + videoWidthDiff);
        sliderContainer.css("top", (videoHeightDiff / 2));
        sliderContainer.css("margin-left", 0);

        slide.height(videoHeight);
        slide.width(videoWidth);

        //hover effect
        $(".slide").mouseover(function() {
            if(win.width() < 1200) return;
            $(this).css("width", videoWidth * scaling);
            $(this).css("height", videoHeight * scaling);
            $(this).css("top", -(videoHeightDiff / 2));
            if($(".slide").index($(this)) == 0){
            }
            else if(($(".slide").index($(this)) + 1) == $(this).parent().children().length) {
                $(this).parent().css("margin-left", -((videoWidth * scaling) - videoWidth));
            }
            else if($(".slide").index($(this))  > 0){
                $(this).parent().css("margin-left", -((videoHeight * scaling) - videoHeight));
            }
            else{
                $(this).parent().css("margin-left", -(0));
            }
            var slides = $(this).parent().children();
            var self = $(this);
            slides.each(function(){
                if(this != self){
                    $(this).find("img").css("opacity", 0.6).css("transition-duration", "400ms");
                }
            })
        }).mouseout(function() {
            if(win.width() < 1200) return;
            $(this).parent().children().find("img").css("opacity", "");
            $(this).css("width", videoWidth * 1);
            $(this).css("height", videoHeight * 1);
            $(this).css("top", 0);
            $(this).parent().css("margin-left", 0);
        });
        if(!(sliderLoaded)){
            var timer = setTimeout(function(){
                sliderContainer.animate({
                    opacity:1
                }, 400);
                sliderLoaded = true;
            },300);
        }
    }

    // SLIDER Swiper


    //initialize swiper when document ready
    var kioskSlider = new Swiper ('.avt-kiosk-slider', {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 2,
        initialSlide: 1,
        loop: true,
        loopAdditionalSlides: 3,
        coverflowEffect: {
         rotate: 60,
         stretch: 0,
         depth: -50,
         modifier: 1,
         slideShadows : true,
        },
        breakpoints: {
            768: {
                slidesPerView: 1
            }
        },
    })

    var fotopolisSlider = new Swiper ('#avt-fotopolis-slider', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                var logoBox = $(this.slides[index]).find(".avt-slide-logo");
                var newDiv = $("<div>"); //Na potrzeby wsadzenia do html
                var newSpan = $("<span>");
                newDiv.append(newSpan);
                newSpan.addClass(className);
                newSpan.append(logoBox.html());
                return newDiv.html();
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })

    var newsSlider = new Swiper ('#avt-news-slider', {
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })

    var footerSlider = new Swiper ('.avt-footer-slider', {
        slidesPerView: 5,
        slidesOffsetAfter: 0,
        // centeredSlides: true,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            479: {
                slidesOffsetAfter: 0,
                slidesPerView: 1
            },
            639: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3,
            },
            1440: {
                slidesPerView: 4,
                slidesOffsetAfter: 250
            }
        },
    })

    var allPackages = new Swiper ('.all-packages-swiper', {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: '1.70',
        spaceBetween: 200,
        loop: true,
        slidesPerGroup: 1,
        loopAdditionalSlides: 1,
        coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows : true
        },
        breakpoints: {
            1024: {
                spaceBetween: 10,
                slidesPerView: '1.10'
            },
            1199: {
                spaceBetween: 80,
                slidesPerView: '1.22'
            },
            1399: {
                spaceBetween: 80,
                slidesPerView: '1.42'
            }
        },
        navigation: {
            nextEl: '.all-packages-next',
            prevEl: '.all-packages-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
        }
    });

    var magazinesSlider = new Swiper ('.magazines-swiper', {
        slidesPerView: 7,
        spaceBetween: 10,
        breakpoints: {
            575: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 3
            },
            1024: {
                slidesPerView: 4
            },
            1199: {
                slidesPerView: 5
            }
        },
        navigation: {
            nextEl: '.avt-magazines-next',
            prevEl: '.avt-magazines-prev'
        }
    });

    var trustUsSlider = new Swiper ('.trust-us-swiper', {
        slidesPerView: 5,
        spaceBetween: 10,
        autoplay: true,
        breakpoints: {
            575: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 3
            },
            1024: {
                slidesPerView: 4
            }
        }
    });

    var targetGroupSwiper = new Swiper ('.target-group-swiper', {
        slidesPerView: 4,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        slidesPerGroup: 1,
        loopAdditionalSlides: 1,
        breakpoints: {
            767: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            1199: {
                slidesPerView: 2,
                spaceBetween: 0
            }
        },
        navigation: {
            nextEl: '.target-group-next',
            prevEl: '.target-group-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
        }
    });

    var otherActionsSlider = new Swiper ('.other-actions-swiper', {
        slidesPerView: 5,
        spaceBetween: 10,
        autoplay: true,
        breakpoints: {
            575: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 3
            },
            1024: {
                slidesPerView: 4
            }
        }
    });

    var contactSlider = new Swiper ('.contact-slider', {
        slidesPerView: 5,
        spaceBetween: 10,
        autoplay: true,
        breakpoints: {
            480: {
                slidesPerView: 1
            },
            575: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 3
            },
            1200: {
                slidesPerView: 4
            }
        }
    });

    function setTabs(){
        var buttons = $(".avt-category-box[data-category]");
        if(buttons.length == 0 ) return;
        var addressLink = new String(document.location);
        if(addressLink.indexOf("print") != -1 || addressLink.indexOf("mobile") != -1  || addressLink.indexOf("online") != -1) {
            if(addressLink.indexOf("print") != -1){
                activeCat = "print";
            }
            else if (addressLink.indexOf("online") != -1) {
                activeCat = "online";
            }
            else if (addressLink.indexOf("mobile") != -1) {
                activeCat = "mobile";
            }
            var oldCat = $(".avt-category-box.active");
            var oldCont = $(".avt-newspaper-detail.active");
            oldCat.removeClass("active");
            oldCont.removeClass("active");
            var newCat = $(".avt-category-box[data-category='"+ activeCat +"']");
            var newCont = $(".avt-newspaper-detail[data-category='"+ activeCat +"']");
            newCat.addClass("active");
            newCont.addClass("active");
        }
        buttons.on("click", function(){
            var self = $(this);
            var activeCat = $(".avt-category-box.active");
            var activeCont = $(".avt-newspaper-detail.active");
            activeCat.removeClass("active");
            activeCont.removeClass("active");
            var category = self.attr("data-category");
            self.addClass("active");
            var container = $(".avt-newspaper-detail[data-category='"+ category +"']");
            container.addClass("active");
        });
    }
    setTabs();

    $(document).ready(function(){
        /*--- Reklama page: Stwórz własny pakiet ---*/
        var id = 0;
        $(document).on('click', '.group-button .label', function() {
            /* open box with list */
            if (!$(this).hasClass('impossible')) {
                var $box = $(this).parent().find('.open-box');
                $box.addClass('opened');

                var boxId = uniqueBoxId($box);
                var condition = openCondition($box);

                $(document).on('click.closeBox' + boxId, function(e) {
                    /* close box with list on click anywhere */
                    if ($(e.target).closest('.open-box').length) return;

                    var $boxAll = $('.group-button .open-box.opened');
                    var $boxLast = $(e.target).closest('.group-button').find('.open-box');

                    $boxAll.each(function () {
                        if (this == $boxLast[0]) {
                          return;
                        }

                        closeBox($(this));
                        backToOpenCondition($(this), condition);
                        isChecked($(this));
                    });
                });
            }
        });

        $('.group-button .close-box').click(function() {
            /* close box and select items on press OK */
            var $box = $(this).closest('.open-box');
            closeBox($box);
            select($box);
            isChecked($box);
        });

        $(document).on('click', '.filter-item .remove-selected-item', function() {
            /* remove selected item */
            var $box = $(this).closest('.filter-item').find('.open-box');
            unselect($(this).parent());
            isChecked($box);
        });

        $(document).on('click', '.group-button .show-offer', function() {
            var $buttonsChecked = $('.group-button .label.checked');
            var $buttonsNoChecked = $('.group-button .label:not(.checked) .anim-red');
            if($buttonsChecked.length === 3) {
                $(this).addClass('showed');
                $('.offer-page').addClass('show');
                $('.filter-item .selected-row').addClass('hide');
                $('.group-button .label').addClass('impossible');
            } else {
                $buttonsNoChecked.removeClass('no-checked');

                $buttonsNoChecked.addClass('no-checked');

                $buttonsNoChecked.one('webkitAnimationEnd oanimationend oAnimationEnd msAnimationEnd animationend', function() {
                    $buttonsNoChecked.removeClass('no-checked');
                });
            }
        });

        $('.edit-offer').click(function() {
            $('.group-button .show-offer').removeClass('showed');
            $('.offer-page').removeClass('show');
            $('.filter-item .selected-row').removeClass('hide');
            $('.group-button .label').removeClass('impossible');
        });

        function uniqueBoxId($box) {
            var boxId;

            if ($box.data('box-id') !== undefined) {
                boxId = $box.data('box-id');
            } else {
                $box.attr('data-box-id', ++id);
                boxId = id;
            }

            return boxId;
        }

        function closeBox($box) {
            $box.removeClass('opened');
            $box.addClass('close');

            $box.one('webkitAnimationEnd oanimationend oAnimationEnd msAnimationEnd animationend', function() {
                $box.removeClass('close');
            });
            $(document).off('click.closeBox' + $box.data('box-id'));
        }

        function openCondition($box) {
            var condition = $box.find('label').map(function() {
                return $(this).find('input:checked').val();
            });

            return condition;
        }

        function backToOpenCondition($box, openCondition) {
            $box.find('input[type="checkbox"]').prop('checked', false);

            for(var i=0; i < openCondition.length; i++) {
                $box.find('input[value="'+openCondition[i]+'"]').prop('checked', true);
            }
        }

        function select($box) {
            var $filterItem = $box.closest('.filter-item'),
                num = $filterItem.data('item-num'),
                $offerCol = $box.closest('.avt-package').find('.offer-item-col[data-item-num="'+num+'"]');

            if ($('input:checked').length) {
                removeSelectedRowHtml($box);
                $filterItem.append('<div class="selected-row clearfix"></div>');

                var selListHtml = '';

                $box.find('input:checked').each(function() {
                    selListHtml += createSelectedItemHtml($(this));
                });

                $filterItem.find('.selected-row').append(selListHtml);

                /* dodanie do podsumowania - Stworzony Pakiet */


                removeOfferItems($offerCol);
                $offerCol.append(selListHtml);

            } else {
                removeSelectedRowHtml($box);
                removeOfferItems($offerCol);
            }
        }

        function unselect($item) {
            var val = $item.data('value'),
                num = $item.closest('.filter-item').data('item-num'),
                $offerItem = $item.closest('.avt-package').find('.offer-item-col[data-item-num="'+num+'"] .selected-item[data-value="'+val+'"]');

            $item.closest('.filter-item').find('input[value="'+val+'"]').prop('checked', false);

            removeSelectedItemHtml($item);
            removeOfferItem($offerItem);
        }

        function createSelectedItemHtml($input) {
            var val, text;

            val = $input.val();
            text = $input.closest('label').find('.input').text();

            return '<div class="selected-item" data-value="'+val+'">'+
                        text +
                        '<span class="remove-selected-item"><svg width="16" height="16" viewBox="0 0 1792 1792"><g class="nc-icon-wrapper" fill="#51586b"><path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19L896 715 715 534q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z"></path></g></svg></span>'+
                    '</div>';
        }

        function removeSelectedItemHtml($item) {
            $item.remove();
        }

        function removeSelectedRowHtml($box) {
            $box.closest('.filter-item').find('.selected-row').remove();
        }

        function removeOfferItems($col) {
            $col.find('.selected-item').remove();
        }

        function removeOfferItem($offerItem) {
            $offerItem.remove();
        }

        function isChecked($box) {
            if ($box.find('input:checked').length) {
                $box.closest('.group-button').find('.label').addClass('checked');
            } else {
                $box.closest('.group-button').find('.label').removeClass('checked');
                removeSelectedRowHtml($box);
            }
        }
        /*--- Reklama page: Stwórz własny pakiet END ---*/

        /*--- Left and Main menu on Reklama Pages ---*/
        $('.avt-reklama-nav .collapse, #menu .collapse').on('show.bs.collapse', function () {
            var id = $(this).attr('id');
            var $control = $(this).parent().find('[data-target="#'+ id +'"]');
            $control.addClass('open');
        });
        $('.avt-reklama-nav .collapse, #menu .collapse').on('hide.bs.collapse', function () {
            var id = $(this).attr('id');
            var $control = $(this).parent().find('[data-target="#'+ id +'"]');
            $control.removeClass('open');
        });
        /*--- Left and Main menu on Reklama Pages End ---*/

        /* --- Scroll To on reklama-pobieralnik --- */
        $('.materials-anchors').on('click', '.anchor',function() {
            var anchorElem = $(this),
                anchor = anchorElem.data('anchor'),
                to = $('#'+anchor).offset().top - $('.materials-anchors').outerHeight();
                $('html, body').animate({
                    scrollTop: to
                }, 500);
        });
        /* --- Scroll To on reklama-pobieralnik End --- */

        /* --- Fixed anchors on reklama-pobieralnik --- */
        function fixedAnchors() {
            var fixed = $('.materials-page .anchors-wrap').offset().top;
            var $anchors = $('.materials-page .materials-anchors');
            if ($(window).scrollTop() >= fixed) {
                $anchors.addClass('fixed');
            } else {
                $anchors.removeClass('fixed');
            }
        }

        if ($('.materials-page .materials-anchors').length) {
            fixedAnchors();

            $(window).scroll(fixedAnchors);

            var fixedTime;
            $(window).resize(function() {
                window.clearTimeout(fixedTime);
                fixedTime = window.setTimeout(function() {
                    fixedAnchors();
                }, 100);
            });
        }
        /* --- Fixed anchors on reklama-pobieralnik End --- */

        /*--- animation background ---*/
        $(document).on('mousedown touchstart', '.avt-anim-bg-nav a', function(event) {
            var elemBg = $(this).find('.anim-bg');

            elemBg.removeClass('pressed');
            elemBg.css({'left': getClickPos(event, $(this)).x, 'top': getClickPos(event, $(this)).y});
            elemBg.addClass('pressed');
        });

        $(document).on('mousedown touchstart', '.avt-anim-bg', function(event) {
            var elemBg = $(this).find('.anim-bg'),
                animfinish = false;

            elemBg.removeClass('pressed');
            elemBg.css({'left': getClickPos(event, $(this)).x, 'top': getClickPos(event, $(this)).y});
            elemBg.addClass('pressed');

            elemBg.one('webkitAnimationEnd oanimationend oAnimationEnd msAnimationEnd animationend', function() {
                animfinish = true;
            });

            $(this).on('mouseup.animbg mouseleave.animbg touchend.animbg touchcancel.animgb', function() {
                if (animfinish) {
                    $(this).find('.anim-bg').removeClass('pressed');
                    $(this).off('mouseup.animbg touchend.animbg touchcancel.animgb');
                } else {
                    var $this = $(this);
                    var interval = setInterval(function() {
                        if (animfinish) {
                            $this.find('.anim-bg').removeClass('pressed');
                            $this.off('mouseup.animbg mouseleave.animbg touchend.animbg touchcancel.animgb');
                            clearInterval(interval);
                        }
                    }, 100);
                }
            });
        });

        function getClickPos(event, elem) {
            if (event.touches !== undefined) {
                return {
                    x : event.touches[0].pageX - elem.offset().left,
                    y : event.touches[0].pageY - elem.offset().top
                };
            }

            if (event.touches === undefined) {
                if (event.pageX !== undefined) {
                    return {
                        x : event.pageX - elem.offset().left,
                        y : event.pageY - elem.offset().top
                    };
                }
                if (event.pageX === undefined) {
                    return {
                        x : event.clientX - elem.offset().left,
                        y : event.clientY - elem.offset().top
                    };
                }
            }
        }
        /*--- animation background End ---*/

        /* --- magnificPopup (like hightslide) --- */
        if ($('.imagesInContent').length) {
            var magnificPopupOptions = {
                type: 'image',
                closeOnContentClick: true,
                mainClass: 'mfp-img-mobile',
                image: {
                        verticalFit: true
                },
                zoom: {
                        enabled: true,
                        duration: 300 // don't foget to change the duration also in CSS
                },
                callbacks: {
                    change: function(item) {
                        this.content.find('.mfp-link-tab').attr('href', item.src);
                    }
                }
            }

            $('.imagesInContent:not([data-oryginal])').magnificPopup(magnificPopupOptions);
        }
        /* --- magnificPopup End --- */
    });
})
// Clear js
document.addEventListener('DOMContentLoaded', function() {

    /* Our marks */
    if (document.getElementsByClassName('our-marks')[0]) {
        var menu = document.getElementById('menu');
        var marks = menu.getElementsByClassName('separator')[0];
        var containerMarks = document.getElementsByClassName('our-marks')[0];
        var close = containerMarks.getElementsByClassName('icon-close')[0];
        var body = document.getElementsByTagName('body')[0];
        marks.addEventListener('click', function() {
            containerMarks.classList.add('open');
            body.style.height = '100vh';
            body.style.overflow = 'hidden';

        });

        close.addEventListener('click', function() {
            containerMarks.classList.remove('open');
            body.style.height = 'auto';
            body.style.overflow = 'auto ';
        });
    }
    /* END Our Marks */


    /* Article navigation */
    var prev = document.getElementsByClassName('avt-prev-but-circle')[0];
    var next = document.getElementsByClassName('avt-next-but-circle')[0];
    var newspaperDetails = document.getElementsByClassName('avt-newspaper-detail');
    var box = document.getElementsByClassName('avt-category-box');

    if (newspaperDetails.length > 1) {
        /* Next button  */
        next.addEventListener('click', function() {

            for (var i = 0; i < newspaperDetails.length; i++) {

                if (newspaperDetails[i].classList.contains('active')) {

                    newspaperDetails[i].classList.remove('active');
                    box[i].classList.remove('active');

                    if ((i + 1) === newspaperDetails.length) {
                        i = -1;
                    }

                    i++;

                    newspaperDetails[i].classList.add('active');
                    box[i].classList.add('active');

                    break;
                }
            }
        });

        /* Prev button  */
        prev.addEventListener('click', function() {

            for (var i = (newspaperDetails.length - 1); i >= 0; i--) {

                if (newspaperDetails[i].classList.contains('active')) {

                    newspaperDetails[i].classList.remove('active');
                    box[i].classList.remove('active');

                    if (i === 0) {
                        i = newspaperDetails.length;
                    }

                    i--;

                    newspaperDetails[i].classList.add('active');
                    box[i].classList.add('active');

                    break;
                }
            }
        });
    }
    else if (newspaperDetails.length === 1) {
        prev.classList.add("d-none");
        next.classList.add("d-none");
    }
    /* END Article navigation */


    /* Scrolling */
    if (newspaperDetails.length !== 0) {
        var scrolling = false;

        var elem = newspaperDetails[0].getElementsByClassName('scroll-item')[0];
        window.addEventListener('scroll', function() {
            scrolling = object.scroll(elem, newspaperDetails[0], scrolling, 50);
        });

    }
    /* End Scrolling */






    /* === Menu === */
    var menuToggleElem = document.querySelector('.menu-toggle'),
        siteMenuElem = document.querySelector('.menu-wrap'),
        siteCloseElem = document.querySelector('.site-close'),
        //siteCanvasElem = document.querySelector('.site-canvas'),
        headerElem = document.querySelector('header'),
        bodyElem = document.querySelector('body'),
        menuStatus = "closed",
        menuWidth,
        timeToggle = 0.45,
        moved,
        excluded = ['.swiper-container'];

    function menuToggle() {
        menuWidth = siteMenuElem.offsetWidth;

        setDuration(siteMenuElem, timeToggle);
        //setDuration(siteCanvasElem, timeToggle);
        setDuration(siteCloseElem, timeToggle);

        if (menuToggleElem.classList.contains('show-menu')) {
            hideMenu();

            afterHide(timeToggle);

        } else {
            siteCloseElem.style.display = 'block';

            showMenu();
        }
    }

    function hideMenu() {
        menuToggleElem.classList.remove('show-menu');

        openBody();

        setTranslateX(siteMenuElem, '0%');
        //setTranslateX(siteCanvasElem, 0);

        siteCloseElem.style.opacity = 0;

        menuStatus = "closed";

        currentPosX = 0;
    }

    function showMenu() {
        closeBody();
        clearTimeout(moved);

        menuToggleElem.classList.add('show-menu');

        setTranslateX(siteMenuElem, '-100%');
        //setTranslateX(siteCanvasElem, menuWidth/3);

        siteCloseElem.style.opacity = 1;

        menuStatus = "opened";

        currentPosX = -menuWidth;
    }

    function afterHide(time) {
        moved = setTimeout(function() {
            siteCloseElem.style.display = '';

            if (document.querySelector('.custom.float')) {
                document.querySelector('.custom.float').style.display = '';
            }
        }, time*1000);
    }

    function closeBody() {
        var bodyWidth =  bodyElem.offsetWidth;
        bodyElem.classList.add('close');
        var bodyWidthClose = bodyElem.offsetWidth;

        if (bodyWidthClose > bodyWidth) {
            bodyElem.style.paddingRight = bodyWidthClose - bodyWidth + 'px';
            //headerElem.style.paddingRight = bodyWidthClose - bodyWidth + 'px';
        }
    }

    function openBody() {
        bodyElem.classList.remove('close');
        bodyElem.style.paddingRight = '';
        //headerElem.style.paddingRight = '';
    }

    function setTranslateX(elem, num) {
        elem.style.MozTransform = 'translateX('+num+')';
        elem.style.MsTransform = 'translateX('+num+')';
        elem.style.WebkitTransform = 'translateX('+num+')';
        elem.style.OTransform = 'translateX('+num+')';
        elem.style.transform = 'translateX('+num+')';
    }

    function setDuration(elem, time) {
        elem.style.MozTransitionDuration = time +'s';
        elem.style.MsTransitionDuration = time +'s';
        elem.style.WebkitTransitionDuration = time +'s';
        elem.style.OTransitionDuration = time +'s';
        elem.style.transitionDuration = time +'s';
    }

    function removeTranslateX(elem) {
        elem.style.MozTransform = '';
        elem.style.MsTransform = '';
        elem.style.WebkitTransform = '';
        elem.style.OTransform = '';
        elem.style.transform = '';
    }

    if (menuToggleElem) {
        menuToggleElem.addEventListener('click', menuToggle);
    }

    if (siteCloseElem) {
        siteCloseElem.addEventListener('click', function() {
            menuWidth = siteMenuElem.offsetWidth;

            setDuration(siteMenuElem, timeToggle);
            setDuration(siteCloseElem, timeToggle);

            hideMenu();

            afterHide(timeToggle);
        });
    }

    /* swipe Menu */
    var startTouchX,
        startTouchY,
        newPosX,
        newPosY,
        lastPosX,
        currentPosX = 0,
        moveDelta = 8,
        changePoint = 60,
        onesBigest,
        sliding,
        firedOnce,
        direction = null;

    var hasTouchStartEvent = 'ontouchstart' in document.createElement( 'div' );

    var touchEvent = ["touchstart", "touchmove", "touchend"],
        mouseEvent = ["mousedown", "mousemove", "mouseup"],
        evStart, evMove, evEnd;

    var startElement;

    if (hasTouchStartEvent) {
        evStart = touchEvent[0];
        evMove = touchEvent[1];
        evEnd = touchEvent[2];
    } else {
        evStart = mouseEvent[0];
        evMove = mouseEvent[1];
        evEnd = mouseEvent[2];
    }

    function getTouches(event) {
        if (event.touches !== undefined) {
            return {
                x : event.touches[0].pageX,
                y : event.touches[0].pageY
            };
        }

        if (event.touches === undefined) {
            if (event.pageX !== undefined) {
                return {
                    x : event.pageX,
                    y : event.pageY
                };
            }
            if (event.pageX === undefined) {
                return {
                    x : event.clientX,
                    y : event.clientY
                };
            }
        }
    }

    function swapEvents(type) {
        if (type === "on") {
            document.addEventListener(evMove, dragMove, false);
            document.addEventListener(evEnd, dragEnd, false);
        } else if (type === "off") {
            document.removeEventListener(evMove, dragMove);
            document.removeEventListener(evEnd, dragEnd);
        }
    }

    function closest(el, selector) {
        var matchesFn;

        /* find vendor prefix */
        ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
            if (typeof document.body[fn] === 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });

        var parent;

        /* traverse parents */
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    }

    function dragStart(event) {
        var ev = event.originalEvent || event || window.event;

        ev.target = ev.target || ev.srcElement;

        var exl = function() {
            for(var i=0; i<excluded.length; i++) {
                if (closest(ev.target, excluded[i])) {return false; break;}
            }

            return true;
        };

        if (!exl()) return;

        swapEvents("on");

        document.addEventListener('dragstart', function(event) { event.preventDefault(); }, false);

        startElement = ev.target;

        startTouchX = getTouches(ev).x;
        startTouchY = getTouches(ev).y;

        menuWidth = siteMenuElem.offsetWidth;

        onesBigest = false;
        sliding = false;
        firedOnce = false;

        lastPosX = newPosX = 0;
    }

    function dragMove(event) {
        var ev = event.originalEvent || event || window.event;

        newPosX = getTouches(ev).x - startTouchX;
        newPosY = getTouches(ev).y - startTouchY;

        if (Math.abs(newPosY) > moveDelta && sliding === false) { swapEvents("off"); return; }

        if (Math.abs(newPosX) > moveDelta) {
            onesBigest = true;
            sliding = true;

            if (!firedOnce) {
                firedOnce = true;

                setDuration(siteMenuElem, 0);
                //setDuration(siteCanvasElem, 0);
                setDuration(siteCloseElem, 0);

                clearTimeout(moved);

                siteCloseElem.style.display = 'block';
            }


            if (onesBigest === true) {
                ev.preventDefault();

                currentPosX = currentPosX + (newPosX - lastPosX);

                if (currentPosX < -menuWidth) {
                    currentPosX = -menuWidth;
                }

                if (currentPosX > 0) {
                    currentPosX = 0;
                }

                setTranslateX(siteMenuElem, currentPosX/menuWidth*100 +'%');
                //setTranslateX(siteCanvasElem, currentPosX/3);

                siteCloseElem.style.opacity = Math.abs(currentPosX/menuWidth);
            }
        }

        lastPosX = newPosX;
    }

    function dragEnd(event) {
        var ev = event.originalEvent || event || window.event;

        ev.target = ev.target || ev.srcElement;

        if (newPosX > 0) {
            direction = "right";
        } else {
            direction = "left";
        }

        if ( (Math.abs(currentPosX) > changePoint && direction === "left")
                || (Math.abs(currentPosX) < menuWidth - changePoint && direction === "right") )
        {

            var proportion = Math.abs(currentPosX / menuWidth),
                slideTime;

            if ( (direction === "left")) {
                /* open menu */
                slideTime = timeToggle * (1 - proportion);

                setDuration(siteMenuElem, slideTime);
                //setDuration(siteCanvasElem, slideTime);
                setDuration(siteCloseElem, slideTime);

                showMenu();
            }

            if ( (direction === "right")) {
                /* close menu */
                slideTime = timeToggle * proportion;

                setDuration(siteMenuElem, slideTime);
                //setDuration(siteCanvasElem, slideTime);
                setDuration(siteCloseElem, slideTime);

                hideMenu();

                afterHide(slideTime);
            }
        } else {

            if (newPosX !== 0) {

                /* go back to current status */
                if (menuStatus === "closed") {
                    setDuration(siteMenuElem, timeToggle);
                    //setDuration(siteCanvasElem, timeToggle);
                    setDuration(siteCloseElem, timeToggle);

                    currentPosX = 0;

                    setTranslateX(siteMenuElem, currentPosX+'%');
                    //setTranslateX(siteCanvasElem, currentPosX);

                    siteCloseElem.style.opacity = 0;

                    afterHide();
                }

                if (menuStatus === "opened") {
                    clearTimeout(moved);
                    setDuration(siteMenuElem, timeToggle);
                    //setDuration(siteCanvasElem, timeToggle);
                    setDuration(siteCloseElem, timeToggle);

                    currentPosX = -menuWidth;

                    setTranslateX(siteMenuElem, currentPosX/menuWidth*100 + '%');
                    //setTranslateX(siteCanvasElem, currentPosX/3);

                    siteCloseElem.style.opacity = 1;
                }
            }
        }

        swapEvents("off");
    }

    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        document.addEventListener(evStart, dragStart, false);
    }
    /* === Menu End === */
});

/* === Add predefined AdFinder package to form::START === */
$( document ).ready(function($) {
    $('.avt-all-packages .order-package').click(function(e){
        e.preventDefault();

        var pakiet = $(this).parent('.swiper-slide'),
        produkty = '',
        nosniki = '',
        grupy = '',
        form = $('#adfform');

        // Get predefined package elements
        produkty = pakiet.find('.avt-offer-items [data-item-num="1"]').html();
        nosniki = pakiet.find('.avt-offer-items [data-item-num="2"]').html();
        grupy = pakiet.find('.avt-offer-items [data-item-num="3"]').html();

        // Clear Custom AdFinder package
        form.find('.package-offer .offer-item-col').empty();
        form.find('.selected-row').remove();
        form.find('.group-button .label').removeClass('checked');

        // Add predefined package elements to AdFinder form
        form.find('.package-offer .offer-item-col[data-item-num="1"]').append(produkty);
        form.find('.package-offer .offer-item-col[data-item-num="2"]').append(nosniki);
        form.find('.package-offer .offer-item-col[data-item-num="3"]').append(grupy);

        // Add predefined package elements to AdFinder form selector
        form.find('.filter-item').append('<div class="selected-row clearfix"></div>');
        form.find('.filter-item[data-item-num="1"] .selected-row').append(produkty);
        form.find('.filter-item[data-item-num="2"] .selected-row').append(nosniki);
        form.find('.filter-item[data-item-num="3"] .selected-row').append(grupy);
        form.find('.filter-item .selected-row').addClass('hide');
        form.find('.filter-item .selected-row .sub-title').remove();

        // Show form
        form.find('.show-offer').addClass('showed');
        form.find('.offer-page').addClass('show');
        form.find('.filter-item .label').addClass('checked');

        //Scroll to form
        $('html, body').animate({
            scrollTop: $(".avt-section-package .avt-package").offset().top-30
        }, 500);

    });
});
/* === Add predefined AdFinder package to form::END === */

/* === Resize portfolio:Tytuly title to same height ::START === */
$( document ).ready(function($) {
    resizePortfolioTitles();
});
window.addEventListener('resize', resizePortfolioTitles);

function resizePortfolioTitles() {
    var tytulyContainer = $('.avt-section-photo'),
    tytulyTitles = tytulyContainer.find('.avt-title'),
    titlesHeight = 0,
    titlesArray = [],
    rowElements = 4;

    if($(window).width() > 768) {
        rowElements = 3;
    } else if($(window).width() > 575) {
        rowElements = 1;
    } else {
        rowElements = 1;
    }

    // Clear height for resize purposes
    tytulyTitles.css('height', '');
    tytulyTitles.each(function(index){
        if(titlesHeight < $(this).height()) {
            titlesHeight = $(this).height();
        }

        if (((titlesArray.length != 'undefined') && (titlesArray.length < rowElements)) && index < tytulyTitles.length-1  ) {
            titlesArray.push($(this));
        } else {
            titlesArray.push($(this));
            $(titlesArray).each(function(){
                $(this).height(titlesHeight);
            });
            // Clear array and height value
            titlesArray = [];
            titlesHeight = 0;
        }
    });
}

/* === Resize portfolio:Tytuly title to same height ::END === */


document.addEventListener("DOMContentLoaded", function(event){

    var scrollableElement = document.querySelector("body");
    var isometricWrap = jQuery("#isometric-wrap");
    var scrolled = false;
    var scrollActive = false;
    var clientTop = false;
    var firstScrolled = false;
    const body = document.querySelector("body");

    function checkScrollDirection(event){
        var delta;
        if (event.wheelDelta){
            delta = event.wheelDelta;
        }else{
            delta = -1 * event.deltaY;
        }

        if (delta < 0){
            return "down";
        }
        else if (delta > 0){
            return "up"
        }

    }
    window.addEventListener('scroll', function(event) {
        if (firstScrolled && jQuery(window).scrollTop() == 0) {
            scrolled = false;
            clientTop = true;
        }
    });

    scrollableElement.addEventListener('wheel', function(event){
        if (isometricWrap.length != 0 && scrolled != true && jQuery(window).scrollTop() == 0) {
            jQuery(window).scrollTop(0);
            event.preventDefault();
            if (scrollActive == false) {
                scrollActive = true;
                var scrollItem = jQuery("#scroll-item");
                scrollItem.stop();
                var lastItem = jQuery(".avt-isometric-bot");
                var isometricHeight = parseFloat(lastItem.css("height")) + lastItem[0].offsetTop;
                var scrollItemHeight = parseFloat(scrollItem.css("height"));
                var scrollPoint = parseInt(isometricHeight - scrollItemHeight);
                if(scrollItem.scrollTop() < scrollPoint || (clientTop && firstScrolled)) {
                    var oldScroll = scrollItem.scrollTop();
                    if (checkScrollDirection(event) == "down") {
                        var newScroll = oldScroll + 500;
                    }
                    else if (checkScrollDirection(event) == "up") {
                        var newScroll = oldScroll - 500;
                    }
                    scrollItem.animate({
                        scrollTop: newScroll,
                        easing: "linear"

                    }, 350, function(){
                        scrollActive = false;
                        if(scrollItem.scrollTop() >= scrollPoint) {
                            scrolled = true;
                            if (!(firstScrolled)) {
                                firstScrolled = true;
                            }
                        }
                    });
                }
                else {
                    scrolled = true;
                    if (!(firstScrolled)) {
                        firstScrolled = true;
                    }
                }

            }
        }
    });


    // Reklama v3

    function loadCounter(element, time){
        if($(element).length) {
            if(counterLoaded) return;
            counterLoaded =  true;
            $(element).each(function () {
                const fixed = parseInt($(this).text()) != $(this).text();
                $(this).prop('Counter',0).animate({
                    Counter: $(this).text()
                }, {
                    duration: time,
                    easing: 'swing',
                    step: function (now) {
                        if (fixed) {
                            $(this).text(now.toFixed(1));
                        } else {
                            $(this).text(Math.ceil(now));
                        }
                    }
                });
            });
        }
    }
    var counters = $(".js-counter-value");
    var counterPoint = $("[data-scroll='animate-counter-point']");
    var counterLoaded = false;
    var myWindow = $(window);
    if (counters.length) {
        myWindow.on("scroll", function(){
            if (counterPoint.length > 0) {
                if(myWindow.scrollTop() + myWindow.height() >= counterPoint.offset().top ){
                    loadCounter(counters, 3000);
                }
            }
        });
    }

    const segmentSlider = document.getElementsByClassName("segments-slider__slider")[0];
    if (segmentSlider) {
        const segmentSwiper = new Swiper(segmentSlider, {
            autoHeight: true,
            allowTouchMove: false,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: '.segments-slider__slider-next',
                prevEl: '.segments-slider__slider-prev',
            },
        });
        const segmentMenu = document.getElementsByClassName("segments-slider__menu")[0];
        if (segmentMenu) {
            segmentMenu.addEventListener("click", function(e){
                if (e.target.dataset.hasOwnProperty("index")) {
                    this.querySelector(".active").classList.remove("active");
                    segmentSwiper.slideTo(e.target.dataset.index);
                    e.target.classList.add("active");
                }
            });
        }
    }

    const groupSlider = document.getElementsByClassName("group-slider__slider")[0];
    const groupSliderMenu = document.getElementsByClassName("group-slider__menu")[0];
    const groupSliderWrap = document.getElementsByClassName("group-slider")[0];
    if (groupSlider && groupSliderMenu) {
        const groupSwiper = new Swiper(groupSlider, {
            autoHeight: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: '.group-slider__slider-next',
                prevEl: '.group-slider__slider-prev',
            },
            on: {
                slideChange: function() {
                    const index = this.realIndex;
                    if (groupSliderMenu) {
                        const active = groupSliderMenu.getElementsByClassName("active")[0];
                        if (active) {
                            active.classList.remove("active");
                            groupSliderMenu.querySelector(`[data-index='${index}']`).classList.add("active");
                            if (groupMenuSwiper) {
                                groupMenuSwiper.slideTo(index);
                            }
                        }
                    }
                }
            }
        });
        const groupMenuSwiper = new Swiper(groupSliderMenu, {
            slidesPerView: "auto",
            on: {
                slideChange: function() {
                    const index = this.realIndex;
                    if (groupSwiper) {
                        groupSwiper.slideTo(index);
                    }
                }
            }
        });
        groupSliderMenu.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("index")) {
                groupSwiper.slideTo(e.target.dataset.index);
            }
        });
        groupSlider.addEventListener("click", function(e){
            if (e.target.classList.contains("group-slider__item-btn")) {
                document.querySelector(`.group-slider__connected-items[data-id='${e.target.dataset.id}']`).classList.add("open");
            }
        });
        groupSliderWrap.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("close")) {
                if (e.target.dataset.hasOwnProperty("id")) {
                    e.target.classList.remove("open");
                }
                else {
                    e.target.closest("[data-id]").classList.remove("open");
                }
            }
        });
    }

    const userBrowser = () => {
        const body = document.querySelector("body");
        // Internet Explorer 6-11

        var isIE = /*@cc_on!@*/false || !!document.documentMode;

        // Chrome 1 - 79

        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

        // Edge (based on chromium) detection
        var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

        // IE
        if(isIE) {
            body.dataset.userBrowser = 'IE';
        }

        // Edge
        if(isEdgeChromium) {
            body.dataset.userBrowser = 'Edge';
        }

        // Opera
        else if((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
            body.dataset.userBrowser = 'Opera';
        }

        // Chrome
        else if(isChrome) {
            body.dataset.userBrowser = 'Chrome';
        }

        // Firefox
        else if(typeof InstallTrigger !== 'undefined') {
            body.dataset.userBrowser = 'Firefox';
        }

    }
    userBrowser();

    const productsModule = document.querySelector(".js-products-articles");
    if (productsModule) {
        productsModule.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("id")) {
                const elementsOld = this.querySelectorAll(".active");
                for (var i = 0; i < elementsOld.length; i++) {
                    elementsOld[i].classList.remove("active");
                }

                const elements = this.querySelectorAll(`[data-id='${e.target.dataset.id}']`);
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.add("active");
                }
                this.classList.add("item-open");
                window.scrollTo(0, this.offsetTop);
            }
            if (e.target.classList.contains("media-products__return") || e.target.classList.contains("media-products__return--mobile")) {
                const elements = this.querySelectorAll(".active");
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove("active");
                }
                this.classList.remove("item-open");
            }
        });

        const productsMenu = productsModule.querySelector(".media-products__menu");
        if (productsMenu) {
            const productsMenuSwiper = new Swiper(productsMenu, {
                slidesPerView: "auto",
                allowTouchMove: false,
                slideToClickedSlide: false,
                breakpoints: {
                    1024: {
                        allowTouchMove: true,
                        slideToClickedSlide: true,
                    },
                    767: {
                        allowTouchMove: true,
                        centeredSlides: true,
                        slideToClickedSlide: true,
                        loop: true,
                        loopedSlides: 8,
                    }
                },
                navigation: {
                    nextEl: '.media-products__menu-next',
                    prevEl: '.media-products__menu-prev',
                },
            });
        }
    }

    const contactForms = document.querySelectorAll(".media-contact__form");
    if (contactForms) {
        for (var i = 0; i < contactForms.length; i++) {
            const inputs = contactForms[i].querySelectorAll("input[type='text']");
            for (var j = 0; j < inputs.length; j++) {
                if (inputs[j].value.trim() != "") {
                    inputs[j].classList.add("filled");
                }
            }

            contactForms[i].addEventListener("change", function(e){
                for (var i = 0; i < inputs.length; i++) {
                    if (inputs[i].value.trim() != "") {
                        inputs[i].classList.add("filled");
                    }
                    else {
                        inputs[i].classList.remove("filled");
                    }
                }
            });

            contactForms[i].addEventListener("submit", function(e){
                e.preventDefault();
                const msg = this.querySelector(".media-contact__message");
                const submit = this.querySelector("input[type='submit']");
                fetch(this.action, {
                    method: "POST",
                    body: new FormData(this)
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.status) {
                            msg.classList.add("success");
                            submit.disabled = true;
                            submit.classList.add("disabled");
                        }
                        else {
                            msg.classList.add("error");
                        }
                        msg.innerText = res.msg;
                    })
                    .catch(err => {
                        msg.classList.add("error");
                        msg.innerText = "Ooops, coś poszło nie tak. Spróbuj ponownie za chwilę.";
                        console.log(err);
                    })
            });
        }
    }

    const contactWrap = document.querySelector(".media-contact__wrap-main");
    if (contactWrap) {
        contactWrap.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("close")) {
                this.classList.remove("open");
                body.classList.remove("overflow");
            }
        });
    }

    const contactBtn = document.querySelector(".media-contact__float-btn");

    if (contactBtn && contactWrap) {
        contactBtn.addEventListener("click", function(e){
            contactWrap.classList.add("open");
            body.classList.add("overflow");
        });
    }
})
